import type { Cart } from '@features/api/shopify/types';
import { useEffect, useMemo } from 'react';
import { isDeepEqual } from 'remeda';
import { useDebounceValue } from 'usehooks-ts';

import { useSession } from 'next-auth/react';
import { apiClient } from '@features/api/api.client';
import { useCartStore } from '@features/webshop/cart/CartStore';
import usePreservedDiscountCode from '@features/webshop/cart/hooks/usePreservedDiscountCode';

function useUpdateCartOnChange({ cart }: { cart?: Cart }) {
	const { data: session } = useSession();

	const selectedSellingPlan = useCartStore((state) => state.selectedSellingPlan);
	const selectedVariantIds = useCartStore((state) => state.selectedVariantIds);
	const [debouncedSelectedVariantIds] = useDebounceValue(selectedVariantIds, 200);
	const [debouncedSelectedSellingPlan] = useDebounceValue(selectedSellingPlan, 200);

	const { discountCode } = usePreservedDiscountCode();

	const memoizedDebouncedSelectedSellingPlan = useMemo(
		() => debouncedSelectedSellingPlan,
		[debouncedSelectedSellingPlan]
	);
	const memoizedDebouncedSelectedVariantIds = useMemo(
		() => debouncedSelectedVariantIds,
		[debouncedSelectedVariantIds]
	);

	const { mutate: updateCart } = apiClient.cart.useUpdateCart();

	useEffect(() => {
		if (!cart) return;

		const variantsInCart = cart.lines.map((line) => ({
			id: line.merchandise.id,
			sellingPlanId: line.sellingPlanAllocation?.sellingPlan?.id,
		}));
		const selectedVariants = memoizedDebouncedSelectedVariantIds.map((id) => ({
			id,
			sellingPlanId: memoizedDebouncedSelectedSellingPlan?.id,
		}));
		const areNewVariantsAddedToCart = !isDeepEqual(selectedVariants, variantsInCart);
		if (!areNewVariantsAddedToCart) return;

		updateCart({
			lineItems: memoizedDebouncedSelectedVariantIds.map((id) => ({
				merchandiseId: id,
				quantity: 1,
				sellingPlanId: memoizedDebouncedSelectedSellingPlan?.id,
			})),
			discountCodes: discountCode ? [discountCode] : undefined,
			cartAttributes: discountCode ? [{ key: 'discount_code', value: discountCode }] : [],
			buyerEmail: session?.user.email,
		});
	}, [memoizedDebouncedSelectedVariantIds, memoizedDebouncedSelectedSellingPlan]);
}

export default useUpdateCartOnChange;
