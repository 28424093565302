import { useSearchParams } from 'next/navigation';
import { useEffect } from 'react';
import { useLocalStorage } from 'usehooks-ts';

function usePreservedDiscountCode() {
	const searchParams = useSearchParams();
	const searchParamsDiscountCode = searchParams.get('discount');

	const [localStorageDiscountCode, setLocalStorageDiscountCode] = useLocalStorage<{
		code: string | null;
		expiresAt: number | null;
	}>('discountCode', {
		code: searchParamsDiscountCode,
		expiresAt: searchParamsDiscountCode ? Date.now() + 30 * 24 * 60 * 60 * 1000 : null,
	});

	useEffect(() => {
		if (!searchParamsDiscountCode) return;

		const expiresAt = Date.now() + 30 * 24 * 60 * 60 * 1000;

		setLocalStorageDiscountCode({
			code: searchParamsDiscountCode,
			expiresAt,
		});
	}, [searchParamsDiscountCode]);

	const validDiscountCode =
		searchParamsDiscountCode ||
		(localStorageDiscountCode.expiresAt && localStorageDiscountCode.expiresAt > Date.now()
			? localStorageDiscountCode.code
			: null);

	return {
		discountCode: validDiscountCode,
	};
}

export default usePreservedDiscountCode;
